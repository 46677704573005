import * as Highcharts from 'highcharts';
import * as Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/es-modules/DataGrid/DataGrid';
import exportModule from 'highcharts/modules/exporting';
import offlineExportingModule from 'highcharts/modules/offline-exporting';
import stockModule from 'highcharts/modules/stock';
import annotationsModule from 'highcharts/modules/annotations';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDashboardPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin';
import DataGridPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin';
import { i18n } from '@/plugins/internationalization/i18n';

Highcharts.setOptions({
    lang: {
        numericSymbols: [null, 'M', 'G', 'T', 'P', 'E'] as any as string[],
        months: [
            i18n.global.t('layouts.dashboard.charts.months.january'),
            i18n.global.t('layouts.dashboard.charts.months.february'),
            i18n.global.t('layouts.dashboard.charts.months.march'),
            i18n.global.t('layouts.dashboard.charts.months.april'),
            i18n.global.t('layouts.dashboard.charts.months.may'),
            i18n.global.t('layouts.dashboard.charts.months.june'),
            i18n.global.t('layouts.dashboard.charts.months.july'),
            i18n.global.t('layouts.dashboard.charts.months.august'),
            i18n.global.t('layouts.dashboard.charts.months.september'),
            i18n.global.t('layouts.dashboard.charts.months.october'),
            i18n.global.t('layouts.dashboard.charts.months.november'),
            i18n.global.t('layouts.dashboard.charts.months.december'),
        ],
    },
});

HighchartsMore(Highcharts);
exportModule(Highcharts);
stockModule(Highcharts);
annotationsModule(Highcharts);
offlineExportingModule(Highcharts);

HighchartsDashboardPlugin.custom.connectHighcharts(Highcharts);
// @ts-ignore
Dashboards.PluginHandler.addPlugin(HighchartsDashboardPlugin);

DataGridPlugin.custom.connectDataGrid(DataGrid);
// @ts-ignore
Dashboards.PluginHandler.addPlugin(DataGridPlugin);

function getWeek(date: Date) {
    date.setUTCHours(0, 0, 0, 0);

    // Thursday in current week decides the year.
    date.setUTCDate(date.getUTCDate() + 3 - ((date.getUTCDay() + 6) % 7));

    // January 4 is always in week 1.
    const week1 = new Date(Date.UTC(date.getUTCFullYear(), 0, 4));

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getUTCDay() + 6) % 7)) / 7);
}

/* eslint-disable no-import-assign */
/* @ts-ignore */
Highcharts.dateFormats = {
    W(timestamp: number) {
        return getWeek(new Date(timestamp));
    },
    Z(timestamp: number) {
        const date = new Date(timestamp);

        const week = getWeek(date);

        const year = date.getUTCFullYear() % 100;

        return `${week}.${year}`;
    },
};
/* eslint-enable no-import-assign */
